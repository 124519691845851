import styled from "styled-components"
import React from "react"
import { Link } from "gatsby"
import { colors } from "../../../const/colors"
import { motion } from "framer-motion"
import {media_queries} from "../../../const/media_queries";
import { NavUlItem, NavUlItem2 } from "./index";

// const variants = {
//     open: { scaleY:1, transform: "scaleY(1)", transition: {duration: 0.2}, height:},
//     closed: { scaleY:0,transform: "scaleY(0)",transition: {duration: 0.2}}
// }
export const RawSubUl = ({className,links, lang, level, show, isMobile, icon }) => {
    const variants = {
        open: { opacity:1, scaleY:1, transform: "scaleY(1)", transition: {duration: 0.2}, height: "auto"},
        closed: { opacity:0, scaleY:0,transform: "scaleY(0)",transition: {duration: 0.2}, height: "0px"}
    }
    return (
        <motion.div className={`${className} level_${level}`} variants={variants} animate={  show ? "open" : "closed" }>
            <ul>
            {links.map((link, index) => {
                // return <li key={index}><Link to={link.uri[lang]}>{icon ? icon : null }{link.name[lang]}</Link></li>
                return <NavUlItem
                    key={index}
                    to={link.uri[lang]}
                    linkname={link.name[lang]}
                    submenu={link.submenu}
                    level="2"
                    isMobile={isMobile}
                    lang={lang}
                />
            })}
        </ul>
        </motion.div>
    )
}

export const RawSubUl2 = ({className,links, lang, level, show, isMobile, icon }) => {
    const variants = {
        opened: { opacity:1, scaleY:1, transform: "scaleY(1)", transition: {duration: 0.2}, height: "auto"},
        closeded: { opacity:0, scaleY:0,transform: "scaleY(0)",transition: {duration: 0.2}, height: "0px"}
    }
    return (
        <motion.div className={`${className} level_${level}`} variants={variants} animate={  show ? "opened" : "closeded" }>
            <ul>
                {links.map((link, index) => {
                    // return <li key={index}><Link to={link.uri[lang]}>{icon ? icon : null }{link.name[lang]}</Link></li>
                    return <NavUlItem2
                        key={index}
                        to={link.uri[lang]}
                        linkname={link.name[lang]}
                        submenu={link.submenu}
                        level="2"
                        isMobile={isMobile}
                        lang={lang}
                    />
                })}
            </ul>
        </motion.div>
    )
}

export const StyledSubUl = styled(RawSubUl)`
  background:${colors.greenColor};
  border-radius:5px;
  color: white;
  position:relative;
  overflow: hidden;
  margin:auto 20px auto auto;
  transform-origin: center top;
  opacity:0;
  ul {
    padding:10px;
    list-style-type:none; 
    margin:auto 20px auto auto;
  }
  a {
    color:white !important;
    padding:10px !important;
    display: flex !important;
    width:100%;
    align-items: center;
    svg {
      margin-right:7px;
    }
  }
  @media screen and (min-width:${media_queries.mobile}px) {
    position:absolute;
    margin:auto;
    min-width:250px;
    overflow:visible;
    top: calc(100% + 15px);
    left:0;
    background: ${colors.grayColor};
    &.level_2 {
      top:0;
      left:calc(100% - 5px);
      background:${colors.greenColor};
      border-radius:0;
      border-left:1px solid ${colors.lightGrey}
    }
    //a {
    //  color:white !important;
    //  padding:10px !important;
    //  display: flex !important;
    //  width:100%;
    //  align-items: center;
    //  svg {
    //    margin-right:7px;
    //  }
    //}
  }
`
export const StyledSubUl2 = styled(RawSubUl2)`
  background:${colors.greenColor};
  border-radius:5px;
  color: white;
  position:relative;
  overflow: hidden;
  margin:auto 20px auto auto;
  transform-origin: center top;
  opacity:0;
  ul {
    padding:10px;
    list-style-type:none; 
    margin:auto 20px auto auto;
  }
  a {
    color:white !important;
    padding:10px !important;
    display: flex !important;
    width:100%;
    align-items: center;
    svg {
      margin-right:7px;
    }
  }
  @media screen and (min-width:${media_queries.mobile}px) {
    position:absolute;
    margin:auto;
    min-width:250px;
    overflow:visible;
    top: calc(100% + 15px);
    left:0;
    background: ${colors.grayColor};
    &.level_2 {
      top:0;
      left:calc(100% - 5px);
      background:${colors.greenColor};
      border-radius:0;
      border-left:1px solid ${colors.lightGrey}
    }
    //a {
    //  color:white !important;
    //  padding:10px !important;
    //  display: flex !important;
    //  width:100%;
    //  align-items: center;
    //  svg {
    //    margin-right:7px;
    //  }
    //}
  }
`
const arrowVariants = {
    open: {opacity:1, scaleY: 1,  transition:{ duration: 0.2 } },
    closed: {opacity:0, scaleY: 0, transition:{ duration: 0.3 } }
}
export const MArrowUp = ({ className, show, isMobile}) => {
    return (<motion.div className={className} variants={arrowVariants} animate={ show ? "open" : "closed" } />)
}
export const ArrowUp = styled(MArrowUp)`
  width:${props => props.isMobile ? "50px" : "100%"};
  position:${props => props.isMobile ? "relative" : "absolute"};
  top: ${props => props.isMobile ? "initial" : "100%"};
  height:${props => props.isMobile ? "initial" : "15px"};
  transform-origin: center bottom;
  opacity:0;
  &:before {
    content: "";
    display:block;
    position: absolute;
    bottom:0;
    left:${ props => props.isMobile ? "20px" : "50%"};
    transform: ${props => props.isMobile ? "translateX(0)" : "translateX(-10px)" };
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${ props => props.isMobile ? colors.greenColor : colors.grayColor};
  }
`

export const NavWrapper = styled.nav.attrs(props => ({
    className: "onMobile"
}))`
  justify-content: center;
  align-items: center;
  width:100%;
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  z-index:100;
  max-width:400px;
  height: 100vh;
  opacity: ${props => !props.isOpen ? 0 : 1};
  background:${colors.grayColor};
  transform: ${props => props.isMobile && !props.isOpen ? "translateX(-105%)" : "translateX(0)"};
  transition: all .4s ease-out;
  @media screen and (min-width:${media_queries.mobile}px) {
    position:relative;
    top:initial;
    bottom:initial;
    width:initial;
    max-width:initial;
    opacity:1;
    height: initial;
    background:transparent;
  }
  @media screen and (min-width: 768px) {
    width:80%
  }
`

export const NavUl = styled.ul`
  display:block;
  padding-top:20px;
  padding-left:20px;
  @media screen and (min-width:${media_queries.mobile}px) {
    display:flex;
    margin:auto;
    padding-top:initial;
    padding-left:initial;
  }
  width:100%;
  justify-content:space-around;
  align-items: center;
  flex-wrap:wrap;
  list-style-type:none;
  margin:100px auto auto auto;
  ul {
    width:100%;
    li {
      padding:initial
    }
  }
  li {
    transition: all .4s ease;
    //padding:10px;
    border-radius:5px;
    a {
      padding:10px;
    }
    &:hover {
      background:${props => props.isMobile ? "transparent" : colors.greenColor};
      a {
        color:white;
      }
      svg {
        color:white;
      }
    }
    .icon {
      display: flex;
      margin-left:2px;
      //position: absolute;
      // left: ${props => props.isMobile ? "calc(100% - 50px)" : "calc(100% + 3px)"};
      // top: ${props => props.isMobile ? "20px" : "12px"};
      color: ${props => props.isMobile ? "white" : colors.grayColor}
    }
    margin-bottom:0;
    position:relative;
  }
  a {
    display:flex;
    text-decoration: none;
    align-items: center;
    color: white;
    font-size:18px;
    padding:20px 10px;
    position:relative;
    @media screen and (min-width:${media_queries.mobile}px) {
      padding:initial;
      color: ${colors.grayColor};
    }
    border-radius:5px;
    transition: all .3s ease-out;
  }
`
