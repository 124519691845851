import { useEffect, useState } from "react"
import {media_queries} from "../const/media_queries";

export const useCheckWindowSize = (size = media_queries.mobile) => {
  const [isMobile, setIsMobile] = useState(null )
  const [width, setWidth] = useState(0)
  useEffect(() => {
    setIsMobile(window.innerWidth < size)
    const onWindowResize = () => {
      setWidth(window.innerWidth)
      if ((window.innerWidth >= size && window.prevWidth < size) || (window.innerWidth < size && window.prevWidth >= size)) {
        window.prevWidth = window.innerWidth
        if (window.innerWidth >= size) {
          setIsMobile(false)
        } else {
          setIsMobile(true)
        }
      }
    }
    window.prevWidth = window.innerWidth

    window.addEventListener('resize', onWindowResize)
    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [size])
  return [isMobile, width]
}