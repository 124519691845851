import React, {useEffect} from 'react';
import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";

function Index({children, initial, variants, rootMargin, styles}) {
    const controls = useAnimation();
    const [ ref, inView ] = useInView({
        rootMargin:rootMargin ? rootMargin : "0px"
    })
    useEffect(() => {
        if(inView){
            controls.start("visible")
        }else {
            controls.start("hidden")
        }
    }, [controls, inView ])
    return (
        <motion.div
            style={styles ? styles : null}
            ref={ref}
            initial={initial}
            animate={controls}
            variants={variants}>
            { children }
        </motion.div>
    );
}

export default Index;