import React from "react"
import { useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import Link from "gatsby-link";


export const Logo = (props) => {
  const data = useStaticQuery(graphql`
      {
          file(name: {eq: "logof"}) {
              childImageSharp {
                  fluid(maxWidth:200){
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)
  return (
      <Link to="/"><Img fluid={data.file.childImageSharp.fluid} /></Link>
  )
}