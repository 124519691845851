import React from "react"
import styled from "styled-components"
import {colors} from "../../const/colors";
import AnimatedInViewWrapper from "../AnimatedInViewWrapper";

export const Footer = styled.footer`
  padding:50px 0 0 0;
  margin-top:0;
  background: ${colors.grayColor};
  @media screen and (min-width: 768px) {
    padding:75px 0 0 0;
  }
  .box-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap:wrap;
    >div {
      width:100%;
      @media screen and (min-width: 768px) {
        width:49%;
      }
      margin-bottom:20px;
      &:last-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        button {
          width:200px;
          padding:10px;
          margin-left:0;
          @media screen and (min-width: 768px) {
            align-self: flex-end;
            margin-right:0;
          }
        }
      }
    }
    .free-consultation {
      max-width:300px !important;
    }
    .social {
      flex-direction: row !important;
      justify-content: flex-start !important;
      @media screen and (min-width: 768px) {
        justify-content: flex-end !important;
      }
    }
    &--three {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap:wrap;
      > div {
        width:100%;
        @media screen and (min-width: 768px) {
          width:32%;
        }
        &.page-links {
          display: flex;
          flex-direction: column;
          margin:20px 0;
          @media screen and (min-width: 768px ) {
            align-items: flex-end;
            justify-content: flex-end;
          }
          ul {
            list-style-type: none;
            margin:0;
          }
          li {
            @media screen and (min-width: 768px) {
              text-align: right;
            }
          }
          a {
            text-decoration: none;
            color:white;
          }
        }
        span {
          &.title {
            text-transform: uppercase;
            font-weight:bold;
            margin-bottom:20px;
            display: inline-block;
            color:white;
            font-size:20px;
          }
        }
      }
    }
  }
  .social {
    display: flex;
    margin-top:30px;
    justify-content: flex-start;
    @media screen and (min-width: 768px) {
      justify-content: flex-end;
    }
    align-items: center;
    flex-wrap:wrap;
    >a {
      min-width:50px;
      height:50px;
      background: white;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right:24px;
      @media screen and (min-width: 768px) {
        margin-left:24px;
        margin-right:0;
      }
      div {
        display: flex;
      }
    }
    svg {
      color: ${colors.grayColor};
    }
  }
  .copyright {
    display: flex;
    justify-content: space-between;
    color:white;
    font-size:.95rem;
    padding:15px 0;
    border-top:1px solid ${colors.lightBg};
    > div:first-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap:wrap;
      >div:first-child {
        width:100%;
        @media screen and (min-width: 600px) {
          width:70%;
        }
      }
    }
    a {
      color: ${colors.greenColor};
      text-decoration:none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top:25px;
  padding-left:20px;
`

export const InfoBox = styled(({className, icon, text, iconImage, step, space, type}) => {
    return (
        <div className={className}>
            {icon && <div style={{marginRight: space ? space : null}}>{icon}</div>}
            {iconImage && <div style={{marginRight: space ? space : null}}><img src={iconImage} alt=""/></div>}
            {step ? <AnimatedInViewWrapper
                initial="hidden"
                variants={{
                    visible: {
                        y:0,
                        opacity:1,
                        transition: {
                            duration:.6,
                            delay: step * 0.3
                        }
                    },
                    hidden: {
                        y:"20px",
                        opacity:0,
                        transition: {
                            duration:.6
                        }
                    }
                }}><div>{type === "email" ? <a href={`mailto:${text}`}>{text}</a> : type === "phone" ? <a href={`tel:${text}`}>{text}</a> : text }</div></AnimatedInViewWrapper> : <div>{type === "email" ? <a href={`mailto:${text}`}>{text}</a> : type === "phone" ? <a href={`tel:${text}`}>{text}</a> : text }</div>}
        </div>
    )
})`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom:10px;
  color: ${props => props.color ? props.color : 'white'};
  >div:first-child {
    margin-right:15px;
    width:${props => props.size ? props.size : "36px"};
    img {
      margin:auto;
    }
    svg {
      color:${ props => props.color ? props.color : "white"};
    }
  }
  a {
    text-decoration: none;
    color: ${props => props.textColor ? props.textColor : "white"};
  }
  >div:last-child {
    color:${ props => props.color ? props.color : "white"};
  }
`