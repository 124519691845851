import styled from "styled-components"

export const Container = styled.div`
  margin:auto;
  padding-right:15px;
  padding-left:15px;
  width:100%;
  @media screen and (min-width: 1200px){
    width:1170px;
  }
`