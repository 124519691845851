import React, { createContext } from "react"
import { useCheckWindowSize } from "../../hooks/checkWindowSize"
import { media_queries} from "../../const/media_queries";

export const GlobalContext = createContext(null);

const GlobalProvider = ({children, lang}) => {
  const [isMobile, window_width] = useCheckWindowSize(media_queries.mobile)
  return (
    <GlobalContext.Provider value={{isMobile, window_width, lang}}>
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalProvider