import React, {forwardRef, useContext, useState} from "react"
import { HeaderWrapper } from "./styles"
import { Container } from "../Container"
import { Logo } from "../Logo"
import { Navigation} from "./Navigation"
import { TopNavWrapper } from "./styles"
import Hamburger from "hamburger-react"
import { GlobalContext} from "../GlobalProvider"
import { LogoWrapper, LogoText} from "../Logo/styles";
import { colors } from "../../const/colors"
import { Link } from "gatsby"
import globalData from "../../data/global/global.json"

const Header = forwardRef(({ siteTitle, isSticky, lang, flags }, ref) => {
  const [isOpen, setOpen] = useState(false)
  const { isMobile } = useContext(GlobalContext)
  return (
  <HeaderWrapper ref={ref} isSticky={isSticky} id="navbar-spy" className="transparent-header">
   <Container>
     <TopNavWrapper>
       <LogoWrapper>
         <Logo />
         <LogoText to={globalData.footer.logo.uri[lang]}>
           <span>Green Flamingo</span>
           <span>{globalData.footer.logo.subTitle[lang]}</span>
         </LogoText>
       </LogoWrapper>
       { isMobile && <div className="hamburger-wrapper"><Hamburger toggled={isOpen} toggle={setOpen} color={isOpen ? "white": colors.grayColor} /></div>}
       <Navigation flags={flags} isOpen={isOpen} />
     </TopNavWrapper>
   </Container>
  </HeaderWrapper>
)})

export default Header
