import styled from 'styled-components'
import { colors } from "../../const/colors"
import { Link } from "gatsby"
import {media_queries} from "../../const/media_queries";

export const LogoWrapper = styled.div`
  display:flex;
  order:1;
  justify-content: ${props => props.flexAlign ? props.flexAlign : "flex-end"};
  align-items: center;
  @media screen and (min-width: ${media_queries.mobile}px){
    order:0;
    justify-content: flex-start;
  }
  .gatsby-image-wrapper {
    width:75px;
    height:75px;
  }
  img {
    display: block;
    width:100%;
  }
`
export const LogoText = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left:20px;
  text-decoration: none;
  color: ${ props => props.color ? props.color : colors.grayColor };
  span {
    line-height: 1;
  }
  >span:first-child {
    font-size:20px;
    text-transform: uppercase;
    font-weight:bold;
    letter-spacing: .9px;
    line-height:25px;
  }
  >span:last-child {
    font-size:16px;
  }
`