import React, {useEffect, useRef, useState, useContext} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import GlobalStyle from "./GlobalStyles"
import GlobalProvider from "./GlobalProvider"
import Header from "./Header"
import { Container } from "./Container"
import {Footer, InfoBox, InfoWrapper} from "./Footer/styles"
import {Link} from "gatsby";
import {colors} from "../const/colors";
import {LogoText, LogoWrapper} from "./Logo/styles";
import {Logo} from "./Logo";
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa"
import { PopupText} from "./Button/styles";
import {FaFacebookF} from "@react-icons/all-files/fa/FaFacebookF";
import {FaLinkedinIn} from "@react-icons/all-files/fa/FaLinkedinIn";
import { RiInstagramFill } from "react-icons/ri/"
import AnimatedInViewWrapper from "../components/AnimatedInViewWrapper"
import GlobalContext from "../context/GlobalContext";
import globalData from "../data/global/global.json"

const Layout = ({ children, lang, location, flags }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
    const gContext = useContext(GlobalContext)
    const [sticky , setSticky ] = useState(false)
    const headerRef = useRef(null)

    let lastScrollTop = 0;
    const handleScroll = (e) => {
        let st = e.target.scrollTop || document.body.scrollTop;
        if (st > lastScrollTop ){
            // downScroll code
            setSticky(headerRef.current.getBoundingClientRect().top <= 0 )
        } else  {
            // upScroll code
            if(st === 0) {
                setSticky(false)
            }
        }
        lastScrollTop = st <= 0 ? 0 : st;
        // setSticky(headerRef.current.getBoundingClientRect().top <= 0 )

    }

    useEffect(() => {
        if(location?.pathname !== "/contact") {
            gContext.setAdditionalPackages([])
        }
    },[])
    useEffect(() => {
        document.querySelector("#gatsby-focus-wrapper").addEventListener('scroll', handleScroll);
        return () => {
            document.querySelector("#gatsby-focus-wrapper").removeEventListener('scroll', handleScroll);
        }
    })
  return (
    <>
        <GlobalStyle />
        <GlobalProvider lang={lang}>
            <Header lang={lang} flags={flags} ref={headerRef} isSticky={sticky} siteTitle={data.site.siteMetadata?.title || `Title`} />
            <main>
                {children}
            </main>
            <Footer>
                <Container>
                    <div className="box-wrapper--three">
                        <div>
                            <LogoWrapper flexAlign="flex-start">
                                <Logo />
                                <LogoText to="/" color="white">
                                    <span>Green Flamingo</span>
                                    <span>{globalData.footer.logo.subTitle[lang]}</span>
                                </LogoText>
                            </LogoWrapper>
                            <InfoWrapper>
                                <AnimatedInViewWrapper
                                    initial="hidden"
                                    variants={{
                                        visible: {
                                            opacity:1,
                                            y:0,
                                            transition: {
                                                duration: .6,
                                                delay:0.3
                                            }
                                        },
                                        hidden: {
                                            opacity:0,
                                            y:"50px",
                                            transition:{
                                                duration:.6
                                            }
                                        }
                                    }}
                                >
                                    <InfoBox type="phone" icon={<FaPhoneAlt size="1.2em" />} text="+34 655 922 998" />
                                </AnimatedInViewWrapper>
                                <AnimatedInViewWrapper
                                    initial="hidden"
                                    variants={{
                                        visible: {
                                            opacity:1,
                                            y:0,
                                            transition: {
                                                duration: .6,
                                                delay:0.6
                                            }
                                        },
                                        hidden: {
                                            opacity:0,
                                            y:"50px",
                                            transition:{
                                                duration:.6
                                            }
                                        }
                                    }}
                                >
                                    <InfoBox icon={<FaMapMarkerAlt size="1.2em" />} text="Barcelona, Spain" />
                                </AnimatedInViewWrapper>
                                <AnimatedInViewWrapper
                                    initial="hidden"
                                    variants={{
                                        visible: {
                                            opacity:1,
                                            y:0,
                                            transition: {
                                                duration: .6,
                                                delay:0.9
                                            }
                                        },
                                        hidden: {
                                            opacity:0,
                                            y:"50px",
                                            transition:{
                                                duration:.6
                                            }
                                        }
                                    }}
                                >
                                    <InfoBox type="email" icon={<FaEnvelope size="1.2em" />} text="info@greenflamingobcn.com" />
                                </AnimatedInViewWrapper>

                            </InfoWrapper>
                        </div>
                        <div className="page-links">
                            <div><span className="title">{globalData.footer.company.title[lang]}</span></div>
                            <div>
                                <ul>
                                    <li><Link to={globalData.footer.company.links.development.uri[lang]}>{globalData.footer.company.links.development.name[lang]}</Link></li>
                                    <li><Link to={globalData.footer.company.links.berry.uri[lang]}>{globalData.footer.company.links.berry.name[lang]}</Link></li>
                                    <li><Link to={globalData.footer.company.links.branding.uri[lang]}>{globalData.footer.company.links.branding.name[lang]}</Link></li>
                                    <li><Link to={globalData.footer.company.links.portfolio.uri[lang]}>{globalData.footer.company.links.portfolio.name[lang]}</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="page-links">
                            <div><span className="title">{globalData.footer.support.title[lang]}</span></div>
                            <div>
                                <ul>
                                    <li><Link to={globalData.footer.support.customizer.uri[lang]}>{globalData.footer.support.customizer.name[lang]}</Link></li>
                                    <li><Link to={globalData.footer.support.cookie.uri[lang]}>{globalData.footer.support.cookie.name[lang]}</Link></li>
                                    <li><Link to={globalData.footer.support.privacy.uri[lang]}>{globalData.footer.support.privacy.name[lang]}</Link></li>
                                    <li><Link to={globalData.footer.support.faq.uri[lang]}>{globalData.footer.support.faq.name[lang]}</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="box-wrapper" style={{marginBottom:"50px"}}>
                        {/*<ButtonGf background={colors.lightBg} bgHoverColor={colors.greenColor} hoverColor="white">Free consultation</ButtonGf>*/}
                        <div className="free-consultation free-consultation--footer">
                            <PopupText url="https://calendly.com/tina-greenflamingo/30min" text={globalData.footer.consultation[lang]} />
                        </div>
                        <div className="social">
                            <a href="https://www.linkedin.com/company/13019715/"><div><FaLinkedinIn size="1.3em" /></div></a>
                            <a href="https://www.instagram.com/greenflamingobcn/"><div><RiInstagramFill size="1.3em" /></div></a>
                            <a href="https://www.facebook.com/greenflamingobcn/"><div><FaFacebookF size="1.3em" /></div></a>
                        </div>
                    </div>
                </Container>
                <div className="copyright">
                    <Container>
                        <div>{globalData.footer.privacy.company[lang]} / <Link color={colors.greenColor} to="/privacy-policy">{globalData.footer.privacy.policy[lang]}</Link></div>
                        <div>Copyright © {new Date().getFullYear()}</div>
                    </Container>
                </div>
            </Footer>
        </GlobalProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
