import React, { useContext, useState, useEffect, useRef } from "react"
import { Link, useStaticQuery } from "gatsby"
import {NavWrapper, StyledSubUl, ArrowUp, NavUl, StyledSubUl2} from "./styles"
import { GlobalContext} from "../../GlobalProvider"
import { IoChevronDown } from "react-icons/io5"
import {colors} from "../../../const/colors";

import EnFlag from "../../../images/englishflag.png"
import EsFlag from "../../../images/spainflag.png"
import LanguageBox from "../../LanguageBox";

export const NavUlItem = ({to, linkname, submenu, isMobile, lang, level}) => {
    const [showUl, setShowUl] = useState(false)
    const ref = useRef()
    const onEnter = (e) => {
        setShowUl(true)
    }
    const onLeave = (e) => {
         setShowUl(false)
    }
    const onClick = (e) => {
        e.stopPropagation();

        setShowUl(prev => !prev)
    }
    // useEffect(() => {
    //     if(isMobile){
    //         ref.current.addEventListener('click', onClick )
    //     }
    //     return () => {
    //         if(isMobile){
    //             ref.current.removeEventListener('click', onClick )
    //         }
    //     }
    // }, [isMobile])
    return (
        <li ref={ref} onMouseEnter={ !isMobile ? onEnter : null} onMouseLeave={!isMobile ? onLeave : null} onClick={isMobile ? onClick : null}>
            <Link to={to}>{linkname} { submenu && <div className="icon"><IoChevronDown size="0.7rem" /></div>}</Link>
            {submenu && <><ArrowUp show={showUl} isMobile={isMobile}/> <StyledSubUl2 show={showUl} isMobile={isMobile} lang={lang} links={submenu.links} level={level} /></>}
        </li>
    )
}

export const NavUlItem2 = ({to, linkname, submenu, isMobile, lang, level}) => {
    const [showUl, setShowUl] = useState(false)
    const ref = useRef()
    const onEnter = (e) => {
        setShowUl(true)
    }
    const onLeave = (e) => {
        setShowUl(false)
    }
    const onClick = (e) => {
        e.stopPropagation();

        setShowUl(prev => !prev)
    }
    // useEffect(() => {
    //     if(isMobile){
    //         ref.current.addEventListener('click', onClick )
    //     }
    //     return () => {
    //         if(isMobile){
    //             ref.current.removeEventListener('click', onClick )
    //         }
    //     }
    // }, [isMobile])
    return (
        <li ref={ref} onMouseEnter={ !isMobile ? onEnter : null} onMouseLeave={!isMobile ? onLeave : null} onClick={isMobile ? onClick : null}>
            <Link to={to}>{linkname} { submenu && <div className="icon"><IoChevronDown size="0.7rem" /></div>}</Link>
            {submenu && <><ArrowUp show={showUl} isMobile={isMobile}/> <StyledSubUl show={showUl} isMobile={isMobile} lang={lang} links={submenu.links} level={level} /></>}
        </li>
    )
}


// export const NavUl = styled.ul`
//   display:block;
//   padding-top:20px;
//   padding-left:20px;
//   @media screen and (min-width:992px) {
//     display:flex;
//     margin:auto;
//     padding-top:initial;
//     padding-left:initial;
//   }
//   width:100%;
//   justify-content:space-around;
//   align-items: center;
//   flex-wrap:wrap;
//   list-style-type:none;
//   margin:100px auto auto auto;
//   li {
//     .icon {
//       display: flex;
//       position: absolute;
//       left: ${props => props.isMobile ? "calc(100% - 50px)" : "calc(100% + 3px)"};
//       top: ${props => props.isMobile ? "20px" : "3px"};
//       color: ${props => props.isMobile ? "white" : colors.grayColor}
//     }
//     margin-bottom:0;
//     position:relative;
//   }
//   a {
//     display:block;
//     text-decoration: none;
//     color: white;
//     font-size:18px;
//     padding:20px 10px;
//     @media screen and (min-width:992px) {
//       padding:initial;
//       color: ${colors.grayColor};
//     }
//     border-radius:5px;
//     transition: all .3s ease-out;
//   }
// `

const englishFlag = {
   textIndent: "-9999px",
   color: colors.greenColor,
    width:"27px",
    height:"18px",
    backgroundImage: `url(${EnFlag})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    borderRadius:"0px"
}

const spainFlag = {
    textIndent: "-9999px",
    color: colors.greenColor,
    width:"27px",
    height:"18px",
    backgroundImage: `url(${EsFlag})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    borderRadius:"0px"
}

export const Navigation = ({isOpen, flags}) => {
    const data = useStaticQuery(graphql`
        {
            globalJson {
                topMenu {
                    links {
                        name {
                            en
                            es
                        }
                        submenu {
                            links {
                                name {
                                    en
                                    es
                                }
                                submenu {
                                    links {
                                        name {
                                            en
                                            es
                                        }
                                        uri {
                                            en
                                            es
                                        }
                                    }
                                }
                                uri {
                                    en
                                    es
                                }
                            }
                        }
                        uri {
                            en
                            es
                        }
                    }
                }
            }
        }
    `)
  const { isMobile, lang } = useContext(GlobalContext)
  return (
    <NavWrapper isMobile={isMobile} isOpen={isOpen}>
      <NavUl isMobile={isMobile}>
          {data.globalJson.topMenu.links.map((link, index) => {
              return <NavUlItem
                  key={index}
                  to={link.uri[lang]}
                  linkname={link.name[lang]}
                  submenu={link.submenu}
                  level="1"
                  isMobile={isMobile}
                  lang={lang}
              />
          })}
          {!isMobile && <LanguageBox flags={flags} />}
          {isMobile && flags && <div style={{
              display:"flex",
              justifyContent:"flex-start",
          }} className="languages">
              <div style={{marginRight:"20px", position:"relative", zIndex:2000}}><Link style={englishFlag} to={flags[0].uri}>En</Link></div>
              <div><Link style={spainFlag} to={flags[1].uri}>Es</Link></div>
          </div>}
      </NavUl>
    </NavWrapper>
  )
}
