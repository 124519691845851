import React, {useState} from 'react';
import styled from "styled-components"
import {Link} from "gatsby";
import { motion } from "framer-motion"
import {FaCommentDots} from "react-icons/fa"
import { colors } from "../../const/colors"
import EnFlag from "../../images/englishflag.png"
import EsFlag from "../../images/spainflag.png"
import {ArrowUp} from "../Header/Navigation/styles";

const LanguageBox = styled( ({className, flags, active }) => {
    const [show, setShow] = useState(false)
    const variants = {
        open: { opacity:1, scaleY:1, transform: "scaleY(1)", transition: {duration: 0.2}, height: "auto"},
        closed: { opacity:0, scaleY:0,transform: "scaleY(0)",transition: {duration: 0.2}, height: "0px"}
    }
    return (
        <div onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} className={className}>
            <FaCommentDots size="25" color={active ? "green": "black"} />
            <ArrowUp show={show} />
            { flags && <motion.div variants={variants} animate={  show ? "open" : "closed" } className="languages">
                {flags.length > 0 && flags.map(f => {
                    return <div key={f.name}>
                        <Link style={{
                            backgroundImage: `url(${
                                f.name === "en" ? EnFlag :
                                f.name === "es" ? EsFlag :
                                null    
                            })`
                        }} to={f.uri}>{f.name}</Link>
                    </div>
                })}
            </motion.div>}
        </div>
    );
})`
  display: flex;
  position: relative;
  justify-content: center;
  ${ArrowUp} {
    top:18px;
  }
  &:hover {
    svg {
      color: ${colors.greenColor} !important;
      cursor: pointer;
    }
  }

  .languages {
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: ${colors.grayColor};
    padding:10px 5px;
    border-radius:6px;
    position:absolute;
    top:120%;
    transform-origin: center top;
    opacity:0;
    a {
      width:27px;
      height:18px;
      display:block;
      border-radius:0;
      text-indent:-9999px;
    }
    div:first-child {
      margin-bottom:7px;
    }
  }
`

export default LanguageBox;
