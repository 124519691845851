import styled from "styled-components"
import { colors } from "../../const/colors"
import {media_queries} from "../../const/media_queries";

export const HeaderWrapper = styled.header`
  display:block;      
  &.transparent-header {
    background-color: ${props => props.isSticky ? "rgba(255,255,255,.95)" : "transparent"};
    //height:94px;
    height:108px;
    padding:7px;
  }
  
  position:${props => props.isSticky ? 'sticky' : 'relative'};
  z-index:200;
  top:${props => props.isSticky ? "0px" : null };
  border-bottom: ${props => props.isSticky ? "1px solid #f7f7f7" : "none"};
   .hamburger-wrapper {
     order:0;
     @media screen and (min-width: ${media_queries.mobile}px){
       order:1
     }
     position:relative;
     width:48px;
     height:48px;
     z-index: 200;
     // background:${colors.greenColor}
   }
`
export const TopNavWrapper = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
  >div {
    width:100%;
    @media screen and (min-width: 768px){
      max-width:265px;
    }
  }
`
